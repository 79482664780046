.profile-wrapper {
  .content-left {
    position: relative;

    .signup-img {
      width: calc(110%);
      margin-left: -10%;
      padding-top: 100px;
      padding-bottom: 100px;
    }

    .signup-dot {
      position: absolute;
      z-index: -1;
      top: -110px;
      left: -125px;
    }
  }

  .content-right {
    display: flex;
    align-items: center;
    position: relative;

    .decor-dot {
      position: absolute;
      top: -30px;
      right: 190px;
      z-index: -1;
    }

    .decor-paint {
      position: absolute;
      top: 255px;
      left: -10px;
      z-index: -1;
    }

    .content-block {
      width: 80%;
      margin-left: 20%;

      .title {
        color: #333;
        font-size: 40px;
        line-height: 40px;
        font-family: 'Kazimir';
        font-weight: 900;

        p {
          font-size: 40px;
          line-height: 40px;
          font-weight: 400;
        }
      }

      .description {
        color: #535353;
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-family: 'Proxima Nova';
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .profile-wrapper {
    .row {
      // padding-top: 3rem;
      flex-direction: column-reverse;

      .content-right {
        .decor-dot {
          left: 10rem;
          height: 9rem;
          top: 14rem;
          z-index: -1;
        }

        .decor-paint {
          bottom: 5rem;
          left: -4rem;
          top: unset;
        }

        .content-block {
          width: unset;
          margin-left: unset;
          .title {
            font-size: 36px;
            p {
              font-size: 34px;
            }
          }

          .description {
            font-size: 16px;
          }
        }
      }

      .content-left {
        .signup-img {
          height: 37rem;
          width: unset;
          margin-left: unset;
        }
      }
    }
  }
}