.signup-wrapper {
  .content-left {
    display: flex;
    align-items: center;
    position: relative;

    .decor-dot {
      position: absolute;
      top: 50px;
      right: 110px;
      z-index: -1;
    }

    .decor-paint {
      position: absolute;
      top: 130px;
      left: -100px;
      z-index: -1;
    }

    .content-block {
      width: 80%;

      .title {
        color: #333;
        font-size: 60px;
        line-height: 70px;
        font-family: 'Kazimir';
        font-weight: 900;
      }

      .description {
        color: #535353;
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-family: 'Proxima Nova';
      }

      .signup-button {
        width: 174px;
        height: 45px;
        font-size: 18px;
        font-family: 'Proxima Nova';
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        color: #fff !important;
        background-color: #f36328 !important;
        border: none !important;
        text-decoration: none;
      }
    }
  }

  .content-right {
    position: relative;
    overflow: visible;

    .notebook-img {
      width: calc(100% + 100px);
      margin-top: 120px;
    }

    .notebook-img-decor {
      position: absolute;
      z-index: -1;
      top: -96px;
      right: 73px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .signup-wrapper {
    .first-row {
      padding-top: 3rem;

      .content-left {
        .decor-dot {
          left: 12rem;
          height: 8rem;
          top: 20rem;
        }

        .decor-paint {
          bottom: 0rem;
          top: unset;
          height: 24rem;
        }

        .content-block {
          width: unset;
          .title {
            font-size: 44px;
          }
        }
      }
    }
  }
}
