.funding-wrapper {
  margin-top: 100px;
  margin-bottom: 250px;
  position: relative;

  .startup-decor-top {
    position: absolute;
    top: -770px;
    left: -100px;
    z-index: -1;
  }

  .startup-decor-bottom {
    position: absolute;
    bottom: -600px;
    left: -100px;
    z-index: -1;
  }

  .startup-decor {
    position: absolute;
    bottom: -200px;
    right: -30px;
    z-index: -1;
  }

  .content-left {
    display: flex;
    align-items: center;
    position: relative;

    .decor-paint {
      position: absolute;
      top: 65px;
      left: -140px;
      z-index: -1;
    }

    .content-block {
      width: 80%;

      .title {
        color: #333;
        font-size: 40px;
        line-height: 40px;
        font-family: 'Kazimir';
        font-weight: 900;

        p {
          font-size: 40px;
          line-height: 40px;
          font-weight: 400;
        }
      }

      .description {
        color: #535353;
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-family: 'Proxima Nova';
      }
    }
  }

  .content-right {
    position: relative;
    overflow: visible;

    .decor-zigzag {
      position: absolute;
      top: -365px;
      left: -100px;
      z-index: -1;
    }

    .startup-img {
      width: calc(100% + 100px);
    }
  }
}

@media only screen and (max-width: 600px) {
  .funding-wrapper {
    margin-bottom: 200px;
    .row {
      .content-left {
        .decor-paint {
          bottom: 0rem;
          top: unset;
        }

        .content-block {
          width: unset;

          .title {
            font-size: 36px;
          }

          .description {
            font-size: 16px;
          }
        }
      }

      .content-right {
        padding-top: 3rem;
        .startup-img {
          height: 21rem;
          width: unset;
        }

        .decor-zigzag {
          height: 11rem;
          bottom: 26rem;
          left: 12rem;
        }
      }
    }
  }
}
