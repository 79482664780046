@font-face {
  font-family: Proxima Nova;
  src: url(../../assets/fonts/ProximaNova/ProximaNova-Regular.ttf);
}
@font-face {
  font-family: ProximaBold;
  src: url(../../assets/fonts/ProximaNova/ProximaNova-Bold.ttf);
}
@font-face {
  font-family: ProximaMedium;
  src: url(../../assets/fonts/ProximaNova/ProximaNova-Medium.ttf);
}
.home {
  font-family: Proxima Nova;
  overflow-y: hidden;
  overflow-x: hidden;
  .hidden {
    display: none !important;
  }
  .width-auto {
    // width: 85%; 
    // margin: 0 auto;
    // max-width: 1440px;
    position: relative;
    margin-bottom: 60px;
  }
  .section-introduction {
    width: 100%;
    min-height: 700px;
    height: 100%;
    background: url("../../assets/images/BG.svg") no-repeat center;
    position: relative;
    background-size: 100%;
    display: flex;
    align-items: center;
    padding: 180px 0 124px;

    &::before {
      content: url("../../assets/images/graphic-2.svg");
      position: absolute;
      top: 40px;
      right: 0;
    }

    &::after {
      content: url("../../assets/images/graphic.svg");
      position: absolute;
      bottom: -40px;
      left: 0;
    }

    .introduction-inner {
      height: 50%;
      margin: 0 auto;
    }

    .content-wrapper {
      align-items: center;
    }

    .introduction-panel {
      z-index: 1;
    }

    .introduction-slogan {
      font-family: ProximaBold;
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #FFE9C3;
    }

    .introduction-title {
      display: flex;
      position: relative;

      h1 {
        // z-index: 100;
        z-index: 1;
        font-family: Kazimir;
        font-style: normal;
        font-weight: 900;
        font-size: 70px;
        line-height: 100px;
        color: #FFFFFF;
      }

      .introduction-title-banner {
        position: absolute;
        width: 267px;
        height: 30px;
        left: 184px;
        top: 47px;
        background-color: #F36328;
        opacity: 0.5;
      }
    }

    .introduction-description {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF;
      opacity: 0.75;
    }

    .introduction-buttons {
      margin-top: 30px;

      *:not(:first-child) {
        margin-left: 10px;
      }

      .introduction-try-button {
        width: 177px;
        height: 43px;
        background: #F36328;
        border-radius: 100px;
        font-family: ProximaBold;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        border-width: 0px;
        text-decoration: none;
        padding: 11px 49px;
        color: #fff;

        &:hover {
          background-color: #FFFFFF;
          color: #F36328;
        }
      }

      .introduction-pricing-button {
        width: 177px;
        height: 43px;
        background: transparent;
        border-radius: 100px;
        font-family: ProximaBold;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        border: 1px solid #FFFFFF;
        text-decoration: none;
        padding: 12px 50px;
        color: #fff;
        cursor: pointer;

        &:hover {
          background-color: #FFFFFF;
          border-width: 0px;
          color: #46608A;
        }
      }
    }

    .introduction-image {
      box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
      filter: drop-shadow(0px 20px 50px rgba(5, 37, 84, 0.18));

      img {
        width: calc(100% + 60px);
        object-fit: fill;
        // @media only screen and(max-width: 1920px) {
        //     width: calc(95% - 1em);
        //     float: right;
        // }
        // @media only screen and(max-width: 1440px) {
        //     width: calc(95% - 1em);
        //     float: right;
        // }

        // @media only screen and(max-width: 1360px) {
        //     width: calc(80% - 1em);
        //     float: right;
        // }
        // @media only screen and(max-width: 1280px) {
        //     width: calc(70% - 1em);
        //     float: right;
        // }
      }
    }

    .mb-home-introduction-panel {
      display: none;
    }
  }

  .home-business-plan-container {
    padding-bottom: 100px;
    position: relative;
    z-index: 1;
    &::before{
      content: url(../../assets/images/right-business.png);
      position: absolute;
      left: 0;
      bottom: 180px;
      z-index: -1;
    }
    .business-plan-header {
      margin-top: 116px;
      position: relative;
      &::before{
        content: url(../../assets/images/right-decord-busines.png);
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: -1;
      }
    }

    .business-plan-title {
      font-family: Kazimir;
      font-style: normal;
      font-weight: 900;
      font-size: 60px;
      line-height: 70px;
      text-align: center;
      color: #333333;
      span{
        position: relative;
        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 20px;
          right: 0;
          bottom: 4px;
          background-color: #FFCB78;
          opacity: 0.8;
          z-index: -1;
        }
      }
    }

    .business-plan-desc {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 140%;
      text-align: center;
      color: #535353;
      p{
        margin: 0;
      }
    }

    .business-plan-content {
      display: flex;
      justify-content: center;
      margin-top: 32px;
      position: relative;
      
      img {
        // width: 100%;
        height: 100%;
        margin-left: 60px;
        z-index: 0;
        border-radius: 20px;
        box-shadow: 0px 10px 35px rgba(3, 37, 84, 0.18);
        // filter: drop-shadow(0px 20px 50px rgba(5, 37, 84, 0.18));
      }
    }

    .business-plan-side-items {
      height: 100%;
      width: 33%;
    }

    .nav-pills .nav-link {
      background: rgba(237, 239, 243, 0.5);
      border-radius: 12px;
      padding: 6px;
      color: #333333;
      padding-left: 40px;
      margin-bottom: 12px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      .content {
        p {
          font-family: Proxima Nova;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 140%;
          color: #535353;
          padding-right: 20%;
        }
        .image {
          display: none;
        }
      }
      ul {
        padding-left: 30px;
        li {
          list-style: none;
          position: relative;
          padding: 7px 0;
          font-weight: 600;
          &::before {
            content: url(../../assets/images/list.png);
            position: absolute;
            left: -25px;
            top: 7px;
            font-size: 14px;
          }
        }
      }
      .title {
        font-family: ProximaMedium;
        font-weight: 700;
        font-size: 20px;
      }
      .content {
        display: none;
      }
      &.active {
        margin-bottom: 10px;
        background: rgba(255, 233, 195, 0.5);
        border-right: 3px solid #F09B00;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          right: -10px;
          width: 0px;
          height: 0px;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 10px solid #F09B00;
          top: 45%;
        }
        .content{
          display: block;
        }
      }
    }

    .business-plan-side-item {
      height: 70px;
      margin-left: 100px;
      margin-bottom: 15px;
      width: 380px;
      display: flex;
      align-items: center;
      
      background: rgba(237, 239, 243, 0.5);
      border-radius: 12px;

      p {
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 38px;
        letter-spacing: -0.47px;
        color: #333333;
        margin-left: 40px;
        margin-top: auto;
      }

      &.active {
        width: 389px;
        height: auto;
        background: none;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .business-plan-slider {
      height: 100%;
      width: 67%;
      overflow: visible;

      .tab-content {
        overflow: visible;

        .tab-panel {
          overflow: visible;
        }
      }
    }
  }

  .home-founder-container {
    background: #EDEFF3;
    border-radius: 20px;
    display: flex;
    padding-left: 45px;
    margin-bottom: 100px;
    position: relative;
    &::before{
      content: url(../../assets/images/MeetFounder.svg);
      position: absolute;
      left: -70px;
      top: -70px;
    }
    &::after{
      content: url(../../assets/images/founder-deco-bottom.svg);
      position: absolute;
      right: -70px;
      bottom: 0px;
    }
    .home-founder-image img {
      margin-top: -35px;
    }
    .home-founder-description {
      padding: 50px;
      .tag{
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #D0A696;
        font-family: ProximaBold;
        margin-bottom: 0;
      }
      .title {
        font-weight: bold;
        font-size: 48px;
        color: #333333;
        font-family: 'Kazimir';
        margin: 0;
        line-height: 58px;

        &.title-line {
          display: inline-block;
          position: relative;
          .text {
            z-index: 100;
            position: relative;
          }

          .line {
            position: absolute;
            width: 100%;
            height: 20px;
            right: 0;
            bottom: 10px;
            background-color: #D0A696;
            opacity: 0.5;
          }
        }
      }
      .description {
        color: #535353;
      }
      .second-paragraph .image {
        display: none;
      }
    }
  }

  .mb-home-why-container {
    display: none;
  }

  .mb-new-letter-container {
    display: none;
  }

  .mb-slider-container {
    display: none;
  }

  .mb-final-container {
    display: none;
  }

  .home-pricing-container {
    position: relative;
    margin-bottom: 120px;
    &::before {
      content: url(../../assets/images/price-top.svg);
      position: absolute;
      left: -145px;
      top: 0px;
    }
    
    &::after {
      content: url(../../assets/images/price-bot.svg);
      position: absolute;
      right: -150px;
      bottom: 50px;
    }
    .home-pricing-header {
      text-align: center;
      .home-pricing-title {
        font-family: 'Kazimir';
        font-style: normal;
        font-weight: 900;
        font-size: 60px;
        line-height: 70px;
        text-align: center;
        color: #333333;
        position: relative;
        display: inline-block;
        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 20px;
          right: 0;
          bottom: 15px;
          background-color: #46608A;
          opacity: 0.2;
        }
      }

      .home-pricing-desc {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 100%;
        text-align: center;
        color: #535353;
      }

      .home-pricing-plans {
        display: flex;
        justify-content: center;
        font-size: 18px;
        font-weight: 700;
        font-family: Proxima Nova;;
        
        .home-pricing-toggle-section {
          margin-right: 5px;
          display: flex;
  
          .monthly-name {
            color: #F36328;
            margin-right: 7px;
          }
          
          .form-switch .form-check-input {
            background-color: #FFFFFF;
            border-color: #959AA1;
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='rgb(243, 99, 40)'/></svg>") !important;
          }
        }

        .yearly-plan{
          margin-left: -7px;

          .off-section {
            color:#FFFFFF;
            background-color: #10AFA7;
            width: 72px;
            height: 22px;
            padding: 4px 8px;
            align-items: center;
            border-radius: 4px;
            font-size: 14px;
          }
        }
      
      }

    }

    .home-pricing-panel {
      margin-left: auto; 
      margin-right: auto; 
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .home-pricing-item {
        width: 320px;
        margin-top: 51px;
        background-size: cover;
        background-origin: content-box;
        background-repeat: no-repeat;
        position: relative;
        box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.03);
        border-radius: 8px;
        text-align: center;
        padding: 30px;
        p{
          font-family: ProximaBold;
          font-size: 32px;
          line-height: 32px;
          text-align: center;
          letter-spacing: 0.2px;
          color: #333333;
          margin-bottom: 0;
        }
        span{
          font-size: 18px;
          line-height: 32px;
          text-align: center;
          letter-spacing: 2px;
          text-transform: uppercase;
          color: #6D6D6F;
          font-family: ProximaMedium;
        }
        ul {
          margin-top: 32px;
          border-top: 2px solid #e0e0e091;
          text-align: left;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 30px;
          li {
            list-style: none;
            font-size: 18px;
            line-height: 22px;
            color: #333333;
            padding-top: 10px;
            padding-bottom: 10px;
            &.disable{
              opacity: 0.5;
              &::before {
                opacity: 0.5;
              }
            }
          }
        }
        .price {
          text-align: left;
          span{
            font-weight: bold;
            font-size: 40px;
            letter-spacing: 0.1px;
          }
          margin-bottom: 24px;
        }
        &:not(:first-child) {
          margin-left: 30px;
        }

        @media (max-width: 700px){
          &:not(:first-child) {
            margin-left: 0px;
          }
          &:first-child {
            margin-bottom: 10px;
            margin-top: 10px;
          }
          &:nth-child(2) {
            margin-bottom: 10px;
          }
        }

        &:first-child {
          background-color: #f363282b;
          border-top: 5px solid #F36328;
          .home-pricing-button {
            border: 2px solid #F36328;
            color: #F36328;
          }
          .price {
            span{
              color: #F36328;
            }
          }
          ul {
            li {
              position: relative;
              &::before {
                content: url(../../assets/images/orange.svg);
                position: absolute;
                left: -35px;
                top: 7px;
              }
            }
          }
        }

        &:nth-child(2) {
          border-top: 5px solid #F09B00;
          background-color: #f09b0030;
          .home-pricing-button {
            border: 2px solid #F09B00;
            color: #F09B00;
          }
          .price {
            span{
              color: #F09B00;
            }
          }
          ul {
            li {
              position: relative;
              &::before {
                content: url(../../assets/images/yellow.svg);
                position: absolute;
                left: -35px;
                top: 7px;
              }
            }
          }
        }

        &:nth-child(3) {
          border-top: 5px solid #46608A;
          background-color: #46608a40;
          .home-pricing-button {
            border: 2px solid #46608A;
            color: #46608A;
          }
          .price {
            span{
              color: #46608A;
            }
          }
          ul {
            li {
              position: relative;
              &::before {
                content: url(../../assets/images/gray.svg);
                position: absolute;
                left: -35px;
                top: 7px;
              }
            }
          }
        }

        .home-pricing-button {
          width: 248px;
          height: 48px;
          background-color: transparent;
          border-radius: 100px;
          font-family: ProximaBold;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          img{
            width: 20px;
            height: 20px;
          }
          &:hover {
            background-color: #FFFFFF;
          }
        }
      }
    }
  }

  .home-services-container {
    .home-services-content{
      position: relative;
      &::before {
        content: url(../../assets/images/tool-top.svg);
        position: absolute;
        left: -70px;
        top: -55px;
      }
      
      &::after {
        content: url(../../assets/images/Union.svg);
        position: absolute;
        right: -60px;
        bottom: -10px;
      }
    }
    .home-services-double{
      display: flex;
      justify-content: space-between;
      margin-top: 100px;
      .services-item{
        width: 49%;
        background: #EDEFF3;
        border-radius: 12px;
        padding: 40px;
        padding-bottom: 0;
        overflow: hidden;
        position: relative;
        &:first-child{
          .detail{
            width: 70%;
          }
          img{
            position: absolute;
            right: 0;
            bottom: 0;

            &.newsletter {
              width: 100%;
            }
          }
        }
        &:last-child{
          z-index: -2;
          img{
            width: calc(100% + 40px);
            height: auto;
            z-index: 100;
            z-index: 2;
          }
          &::after{
            z-index: -2;
            content: url(../../assets/images/podcas-center.svg);
            position: absolute;
            right: 30%;
            margin: 0 auto;
            bottom: 0px;
            width: auto;
          }
        }
         
      }
      .content-services {
        .title{
          font-weight: 900;
          font-size: 34px;
          line-height: 120%;
          color: #333333;
          font-family: Kazimir;
        }
        .detail{
          font-weight: normal;
          font-size: 18px;
          line-height: 140%;
          color: #535353;
          margin: 0;
        }
      }
    }
    .home-services-item{
      border-radius: 12px;
      padding: 0 0 0 60px;
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      .content-services {
        padding-right: 70px;
        .title{
          font-weight: 900;
          font-size: 34px;
          line-height: 120%;
          color: #333333;
          font-family: Kazimir;
        }
        .detail{
          font-weight: normal;
          font-size: 18px;
          line-height: 140%;
          color: #535353;
        }
      }
      &.for-founder{
        background: rgba(208, 166, 150, 0.4);
      }
      &.for-invester{
        background: rgba(255, 233, 195, 0.5);
      }
      img {
        border-radius: 14px;
      }
    }
    .home-services-header {
      width: 100%;

      .home-services-title {
        font-family: Kazimir;
        font-style: normal;
        font-weight: 900;
        font-size: 60px;
        line-height: 70px;
        text-align: center;
        color: #333333;
        span{
          position: relative;
          display: inline-block;
          &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 20px;
            right: 0;
            bottom: 10px;
            background-color: #46608A;
            opacity: 0.2;
          }
        }
      }
    }
  }

  .home-final-container {
    position: relative;
    overflow: hidden;

    &::before{
      content: url(../../assets/images/Vector.svg);
      position: absolute;
      right: -100px;
      bottom: -70px;
    }
    .home-final-content {
      background: url(../../assets/images/home-final.png) center;
      background-size: 100%;
      background-repeat: no-repeat;
      position: relative;
      padding: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 64px;
      .home-content{
        span{
          font-size: 18px;
          line-height: 22px;
          letter-spacing: 2px;
          text-transform: uppercase;
          color: #FFE9C3;
          font-family: ProximaBold;
          opacity: 0.75;
        }
        p{
          font-family: Kazimir;
          margin-bottom: 0;
          font-style: normal;
          font-weight: bold;
          font-size: 60px;
          line-height: 70px;
          color: #FFFFFF;
        }
      }
      .home-final-content-button {
        width: 270px;
        height: 54px;
        background: #F36328;
        border-radius: 100px;
        border-width: 0px;
        font-family: ProximaBold;
        font-size: 20px;
        line-height: 24px;
        padding: 15px 86px;
        color: #fff;
        text-decoration: none;
  
        &:hover {
          background: #FFFFFF;
          color: #F36328;
        }
      }
    }
  }
}

@media only screen and (min-width: 1441px) {
  .home {
    .section-introduction {
      min-height: 900px;
    }
  }
}

@media only screen and (width: 1440px) {
  .home {
    .section-introduction {
      background-size: 100%;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .home {
    .section-introduction {
      background-size: 140%;
    }

    .home-introduction-image {
      img {
        width: 100%;
      }
    }
  }
}

@media only screen and(min-width: 768px) {
  .section-mb {
    display: none;
  }
}
@media only screen and(max-width: 959px) {
  .container {
    padding: 0 30px !important;
  }
  .section-mb {
    margin-left: 15px;
    margin-right: 15px;
    padding: 24px;
  }
  .section-contain{
    .title{
      font-family: Kazimir;
      font-style: normal;
      font-weight: 900;
      font-size: 28px;
      line-height: 36px;
      color: #333333;
      margin: 0;
      text-align: center;
      span{
        padding: 5px;
        padding-bottom: 2px;
      }
    }
  }
  .home {
    overflow-x: hidden;
    .section-introduction {
      height: auto;
      padding: 150px 0 200px;
      background: url("../../assets/images/m-bg.svg") no-repeat center;
      background-size: 100%;

      .content-wrapper {
        > div {
          &:first-child {
            margin-bottom: 70px;
          }
        }
      }
      
      .introduction-image {
        img {
          width: 100%;
        }
      }
    }

    .home-business-plan-container {
      &::before {
        transform: scale(0.8);
        left: -80px;
      }

      &::after {
        content: url(../../assets/images/right-decord-busines.png);
        position: absolute;
        right: -30px;
        bottom: -20px;
        z-index: -1;
        transform: scale(0.7);
      }

      .business-plan-header {
        margin: 30px 0;
        &::before { 
          display: none;
        }
      }

      .business-plan-title {
        font-size: 28px;
        line-height: 36px;
        span::before {
          height: 9px;
        }
      }
      .business-plan-desc {
        font-size: 14px;
        p {
          display: inline;
        }
      }

      .business-plan-content {
        margin-top: 0;
      }

      .business-plan-side-items {
        width: 100%;
      }

      .business-plan-slider {
        display: none;
      }

      .nav-pills .nav-link {
        padding: 20px;

        &.active {
          border-right: none;
          border-bottom: 3px solid #F09B00;

          &::before {
            display: none;
          }
        }

        .title {
          font-size: 16px;
        }

        .content {
          p {
            padding-right: 0;
          }
          .image {
            display: block;
          }
        }

        p,
        li {
          font-size: 14px;
        }

        img {
          margin-left: 0;
        }
      }
    }
    .home-founder-container {
      background-image: none;
      width: 100%;
      max-width: 100%;
      padding: 0 !important;
      border-radius: 0;

      &::before {
        left: -100px;
        top: -120px;
        transform: scale(0.5);
      }

      &::after {
        display: none;
      }
      img {
        display: block;
      }

      .home-founder-image img {
        display: none;
      }
      .home-founder-description { 
        padding: 50px 12px;
        width: 100%;

        .tag {
          font-size: 11px;
        }

        .title {
          font-size: 28px;
          line-height: 36px;
          &.title-line {
            .line {
              height: 16px;
              bottom: 0;
            }
          }
        }

        .description {
          font-size: 14px;
        }

        .second-paragraph {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          .image {
            display: block;
          }
        }
      }
    }
    .home-pricing-container {
      margin-bottom: 100px;
      padding: 0 !important;
      &::before {
        left: -110px;
        top: -90px;
        transform: scale(0.3);
      }

      &::after {
        content: url(../../assets/images/wave.svg);
        right: -20px;
        top: -100px;
      }
      .home-pricing-header {
        width: 90%;
        margin: 0 auto;
        .home-pricing-title {
          font-size: 28px;
          line-height: 36px;

          &::before {
            height: 9px;
            bottom: 6px;
          }
        }

        .home-pricing-desc {
          font-size: 14px;
        }
      }

      .home-pricing-panel {
        // overflow-x: auto;
        // justify-content: flex-start;
        // -webkit-overflow-scrolling: auto;
        .home-pricing-item {
          margin-top: 0;
          min-width: 275px;
          // &:not(:first-child) {
          //   margin-left: 20px;
          // }

          .home-pricing-button {
            width: 100%;
          }
        }
      }
    }
    .home-services-container {
      .home-services-header {
        .home-services-title {
          font-size: 28px;
          line-height: 36px;
          span::before {
            height: 9px;
            bottom: 5px;
          }
        }
      }

      .home-services-item {
        flex-direction: column;
        padding: 0;
        margin-bottom: 24px;
        .content-services {
          padding: 30px 20px 0;
          .title {
            font-size: 28px;
          }

          .detail {
            font-size: 16px;
          }
        }

        .content-image {
          width: 100%;
          img {
            width: 90%;
            height: auto;
          }
        }

        &.for-founder {
          .content-image {
            text-align: end;
          }
        }

        &.for-invester {
          flex-direction: column-reverse;
        }
      }

      .home-services-double {
        flex-direction: column;
        margin-top: 95px;
        .services-item {
          width: 100%;
          padding: 0;
          margin-bottom: 24px;
          img {
            position: static !important;
          }
          .content-services {
            padding: 20px 20px 0;
            .title {
              font-size: 28px;
            }
  
            .detail {
              font-size: 16px;
              width: 100% !important;
            }
          }

          &:first-child {
            .content-image {
              margin-top: -70px;
            }
          }

          &:last-child {
            &::after {
              right: 15%;
              bottom: -30px;
              transform: scale(0.6);
            }

            .content-image {
              margin-top: 30px;
            }
          }
        }
      }
    }
    .home-final-container {
      background: url('../../assets/images/final_sp.png') no-repeat center;
      background-size: 100%;
      height: 1200px;
      padding-top: 100px;
      .home-final-content {
        padding: 70px 30px 0;
        background: none;
        flex-direction: column;
        align-items: flex-start;

        .home-final-content-button {
          width: 100%;
          text-align: center;
          margin-top: 30px;
        }
      }
    }
    .mb-new-letter-container {
      margin-top: 80px;
      display: block;
      width: 100%;
      .mb-new-letter-image {
        background: url(../../assets/images/new-letter.png) center;
        height: 600px;
        background-size: cover;
        background-repeat: no-repeat;
      };

      .mb-new-letter-form {
        margin-top: 30px;
        padding: 0px 40px;
        display: flex;
        position: absolute;
        width: 100%;

        .form-control {
          height: 40px;
          border: 2px solid #E2E2E2;
          box-sizing: border-box;
          border-radius: 100px;
        }

        button {
          position: absolute;
          right: 44px;
          width: 104px;
          height: 32px;
          top: 4px;
          background: #46608A;
          border-radius: 100px;

          font-family: Proxima Nova;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 14px;
          align-items: center;
          color: #FFFFFF;
        }
      }
    }
    .mb-slider-container {
      margin-top: 118px;
      margin-bottom: 50px;
      display: block;
      position: relative;
      .new-resource-right{
        position: absolute;
        left: 0;
        top: -220px;
      }
      .carousel-control-next,
      .carousel-control-prev{
        display: none;
      }
      .mb-slider-item {
        display: flex;
        justify-content: center;

        .mb-slider-image {
          width: 325px;
          position: relative;
          padding: 40px 30px;
          padding-bottom: 0;
          height: 512px;
          overflow: hidden;
          border-radius: 19px;

          .detail{
            span{
              display: block;
              font-family: Kazimir;
              font-style: normal;
              font-weight: 900;
              font-size: 28px;
              color: #333333;
              width: 100%;
              text-align: center;
            }
            p{
              font-family: Proxima Nova;
              font-size: 16px;
              line-height: 20px;
              color: #717179;
              margin-top: 15px;
              margin-bottom: 20px;
            }
          }
           
          .mb-slider-button-1 {
            width: 144px;
            height: 39px;
            bottom: 163px;
            left: 30px;
            border: none;
            background: #F09B00;
            border-radius: 100px;
            font-family: ProximaBold;
            font-style: normal;
            font-size: 14px;
            line-height: 17px;
            color:#FFFFFF;
          }

          .mb-slider-button-2 {
            position: absolute;
            width: 144px;
            height: 39px;
            bottom: 233px;
            right: 30px;
            border: none;

            background: #46608A;
            border-radius: 100px;
            font-family: ProximaBold;
            font-style: normal;
            font-size: 14px;
            line-height: 17px;
            color:#FFFFFF;
          }

          .mb-slider-button-3 {
            width: 144px;
            height: 39px;
            border: none;
            background: #7A5C58;
            border-radius: 100px;
            font-family: ProximaBold;
            font-style: normal;
            font-size: 14px;
            line-height: 17px;
            color:#FFFFFF;
            margin-top: 38px;
          }

          .mb-slider-button-4 {
            width: 144px;
            height: 39px;
            bottom: 193px;
            left: 30px;
            border: none;

            background: #F36328;
            border-radius: 100px;
            font-family: ProximaBold;
            font-style: normal;
            font-size: 14px;
            line-height: 17px;
            color:#FFFFFF;
          }
        }

        .slider-1 {
          background: #FFE9C3;
          .slide-img{
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 2;
          }
          .slide-decord{
            position: absolute;
            bottom: 70px;
            right: 0;
            z-index: 0;
          }
          .slide-bot{
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
          }
        }

        .slider-2 {
          background: #EDEFF3;
          button{
            z-index: 2;
          }
          .slide-img{
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 2;
          }
          .block-decord{
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 0;
          }

        }

        .slider-3 {
          background: #DADFE8;
          .slide-img{
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 2;
          }
          .slide-decord{
            position: absolute;
            bottom: 0px;
            right: 0;
            z-index: 0;
          }
          .slide-bot{
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
          }
        }

        .slider-4 {
          background: #E3CAC0;
          .slide-img{
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 2;
          }
          .slide-bot{
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
          }
        }
      }

      .mb-slider-navigator {
        text-align: center;
      }

      .carousel {
        ol.carousel-indicators {
          bottom: -41px;

          li {
            background-color: #E2E2E2;
            height: 8px;
            width: 8px;
            border-radius: 4px;
            border-top: none;
            border-bottom: none;

            &.active {
              background-color: #959AA2;
            }
          }
        }
      }
    }
    .mb-final-container {
      position: relative;
      display: block;
      background: #46608A;
      z-index: 0;
      margin: 0;
      padding: 0;
      padding-top: 50px;
      .image-decor{
        position: absolute;
        left: 0;
        bottom: -20px;
        z-index: -1;
      }
      .image-top{
        z-index: 2;
      }
      .line {
        position: absolute;
        bottom: -50px;
        z-index: -2;
        right: 20px;
      }
      .mb-final-signup-form {
        padding: 0px 40px;
        width: 100%;
        position: relative;
        .form-control {
          height: 40px;
          border: 2px solid #E2E2E2;
          box-sizing: border-box;
          border-radius: 100px;
          padding-top: 10px;

        }

        button {
          position: absolute;
          right: 44px;
          width: 104px;
          height: 32px;
          top: 4px;
          background: #F09B00;
          border-radius: 100px;
          border: none;
          font-family: ProximaBold;
          font-size: 14px;
          line-height: 14px;
          color: #FFFFFF;
        }
      }
    }

    .what-includes-section {
      background: #FFE9C3;
      border-radius: 38px;
      padding-top: 42px;
      padding-bottom: 66px;
      margin-top: 91px;
      margin-right: 35px;
      position: relative;
      .section-contain{
        .title{
          span{
            background: #F36328;
          }
        }
        ul {
          padding: 10px 0;
          margin: 0;
          li{
            padding: 10px 0;
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            
            p{
              margin: 0;
              font-family: ProximaBold;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              color: #000000;
              span{
                font-family: Proxima Nova;
                font-weight: 400;
              }
            }
            img{
              margin-right: 17px;
            }
          }
        }
      }
      .shadow{
        position: absolute;
        top: -20px;
        right: -20px;
        background: #F09B00;
        z-index: -1;
        border-radius: 38px;
        padding-left: 40%;
        padding-bottom: 15%;
      }
      .shadow-top{
        position: absolute;
        top: -27%;
        left: -15px;
        z-index: -2;
      }
      .shadow-bot{
        position: absolute;
        bottom: -23%;
        right: -34px;
      }
    }
    .meet-founder-section{
      padding: 20px;
      padding-top: 43px;
      background: rgba(70, 96, 138, 0.1);
      margin: 0;
      margin-top: 60px;
      padding-bottom: 0;
      position: relative;
      .founder-decor{
        position: absolute;
        right: 0;
        bottom: -100px;
      }
      .section-contain{
        .title{
          span{
            background: #F09B00;
          }
        }
        .sub-title{
          font-family: Kazimir;
          font-style: normal;
          font-weight: 900;
          font-size: 20px;
          line-height: 20px;
          color: #333333;
          text-align: center;
          margin-top: 10px;
        }
        .detail{
          margin-top: 41px;
          span{
            font-family: ProximaBold;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            color: #333333;
            text-align: left;
          }
          p{
            font-family: Proxima Nova;
            font-style: normal;
            font-size: 14px;
            line-height: 17px;
            color: #717179;
            margin-top: 10px;
          }
          .special-detail{
            display: flex;
            justify-content: space-between;
            img{
              margin-left: -24px;
            }
          }
        }
      }
    }
    .mb-new-letter-container{
      padding: 16px;
      margin: 0;
      position: relative;
      .new-decor{
        position: absolute;
        right: 0;
        top: -15px;
      }
      .new-decor-right{
        position: absolute;
        right: 0;
        top: 150px;
      }
      .section-contain{
        margin-top: 100px;
        text-align: center;
        .title{
          span{
            background: #D0A696;
          }
        }
      }
      img{
        margin-top: 28px;
      }
      .detail{
        padding: 0 24px;
        span{
          font-family: ProximaBold;
          font-style: normal;
          font-size: 14px;
          line-height: 17px;
          color: #333333;
          text-align: left;
          margin-top: 37px;
          display: block;
          &.gray{
            margin-top: 0px;
            color: #717179;
          }
        }
        p{
          font-family: Proxima Nova;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #717179;
          margin-top: 10px;
          text-align: left;
        }
      }
    }
    .why-container{
      padding: 16px;
      margin: 0;
      position: relative;
      .why-decor{
        position: absolute;
        top: 70px;
        left: -16px;
      }
      .section-contain{
        text-align: center;
        margin-top: 100px;
        .title{
          span{
            background: #D0A696;
          }
        }
      }
      img{
        margin-top: 28px;
      }
      .detail{
        padding: 0 24px;
        span{
          font-family: ProximaBold;
          font-style: normal;
          font-size: 14px;
          line-height: 17px;
          color: #333333;
          text-align: left;
          margin-top: 37px;
          display: block;
          &.gray{
            margin-top: 0px;
            color: #717179;
          }
        }
        p{
          font-family: Proxima Nova;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #717179;
          margin-top: 10px;
          text-align: left;
        }
      }
    }
    .mb-section-final{
      position: relative;
      padding: 0;
      margin: 0;
      margin-top: 70px;
      .action-line{
        position: absolute;
        right: 0;
        bottom: -100px;
        z-index: -1;

      }
      .image-top{
        z-index: 0;
        width: 100%;
        margin-bottom: -5px;
      }
      .content{
        position: absolute;
        z-index: 2;
        bottom: -10px;
        right: 40px;
        text-align: right;
        span{
          font-family: Kazimir;
          display: block;
          font-style: normal;
          font-weight: 900;
          font-size: 28px;
          line-height: 40px;
          text-align: right;
          color: #FFFFFF;
        }
        p{
          font-family: Proxima Nova;
          font-size: 18px;
          line-height: 22px;
          text-align: right;
          color: #FFFFFF;
          padding-left: 40px;
          margin: 0;
          margin-top: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .home {
    .section-introduction {

      &::before {
        top: 35%;
        z-index: 1;
        transform: scale(0.3);
        right: -90px;
      }

      &::after {
        transform: scale(0.6);
        left: -50px;
        bottom: -10px;
      }

      .introduction-slogan {
        font-size: 11px;
        line-height: 13px;
        margin-bottom: 8px;
      }

      .introduction-title {  
        h1 {
          // z-index: 100;
          z-index: 1;
          font-size: 36px;
          line-height: 40px;
        }
  
        .introduction-title-banner {
          position: absolute;
          width: 138px;
          height: 17px;
          left: 98px;
          top: 17px;
        }
      }
  
      .introduction-description {
        font-size: 16px;
        line-height: 19.5px;
      }

      .introduction-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .introduction-btn {
          width: 50%;
          height: 43px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .introduction-image {
        position: relative;
        height: 284px;

        img {
          width: 455px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    .home-business-plan-container {
      &::after {
        right: -60px;
        bottom: 0;
        transform: scale(0.45);    
      }
    }

    .home-founder-container {
      .home-founder-description { 
        padding-bottom: 0;
        .second-paragraph {
          position: relative;
          padding-top: 20px;
          .image {
            margin-left: 0;
            position: absolute;
            left: -12px;
            bottom: 0;
          }

          div:last-child {
            padding-left: 150px;
          }
        }
      }
    }

    .home-final-container {
      height: 700px;
      .home-final-content {
        .home-content {
          span {
            font-size: 11px;
          }

          p {
            font-size: 28px;
            line-height: 100%;
          }
        }

        .home-final-content-button {
          height: 43px;
          font-size: 16px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

}

@media only screen and (max-width: 400px) {
  .home {
    .home-founder-container {
      .home-founder-description { 
        .second-paragraph {
          padding-top: 0;
        }
      }
    }

    .home-business-plan-container {
      .business-plan-desc {
        line-height: 100%;
      }
    }
  }
}
