.Layout-container {
  .header-img-home {
    position: absolute;
    width: 100%;
    max-width: 100%;
    height: 850px;
    object-fit: contain;
  }
  .header-container {
    // width: 90%;
    max-width: 100%;
    .header {
      z-index: 99;

      flex: 1;
      display: inline-flex;
      width: 100%;
      padding-right: 0;
      padding-left: 0;
      .header-logo {
        display: flex;
        height: 80px;
        align-items: center;
      }

      .navbar-collapse {
        justify-content: flex-end;
        margin-right: 200px;
    
        .navbar-nav {
          color: #fff;
          opacity: 0.5;
          font-size: 1rem;
          font-family: ProximaBold;
          margin-left: 40px;
          padding-bottom: 1rem;
          padding-top: 1rem;

          &:hover {
            cursor: pointer;
          }
    
          &.active {
            opacity: 1;
            border-bottom: 2px solid #f09b00;
          }
        }
      }

      .header-tabs {
        text-decoration: none;
        display: -webkit-inline-box;

        @media only screen and (max-width: 1280px) {
          margin-left: 80px;
        }

        @media only screen and (max-width: 768px) {
          margin-left: 10px;
        }

        .header-tab-item,
        .header-tab-item__black {
          // margin-left: 40px;
          font-family: ProximaBold;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 100%;
          color: #FFFFFF !important;
          opacity: 0.5;
          height: 32px;
          padding-right: 0px !important;
          padding-left: 0px !important;

          &.active {
            opacity: 1;
            border-bottom: 2px solid orange;
          }

          &:hover {
            border-bottom: 2px solid #FFFFFF;
            text-decoration: none;
          }
        }
        .header-tab-item__black {
          color: #000 !important;
        }
      }

      .header-buttons {
        @media only screen and (max-width: 500px) {
          .header-button-login,.header-button-login_black,
          .header-button-join, .header-button-join_black {
            display: none;
          }
    
          .header-nav-toggler_black {
            display: block;
            background: none;
            border: none;
          }
        }

        .header-button-login{
          margin-bottom: 5px;
        }
        .header-button-login,
        .header-button-login__black {
          font-family: ProximaBold;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          color: #FFFFFF;
          text-decoration: none;
        }

        .header-button-login__black {
          color: #333;
        }

        .header-button-join {
          width: 156px;
          text-align: center;
          height: 35px;
          background-color: #F09B00;
          border-radius: 100px;
          margin-top: -8px;
          margin-left: 26px;
          border-width: 0px;
          font-family: ProximaBold;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          padding: 8px 40px;
          text-decoration: none;
          color: #fff;
        }

        .header-nav-toggler {
          display: none;
        }
      }
    }

    .menu-collapse {
      width: 100vw;
      height: 100vh;
      overflow-y: hidden;
      background-color: #282828;
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;

      .menu-collapse-header {
        padding: 30px;

        button {
          position: absolute;
          right: 30px;
          background: none;
          border: none;

          &:hover {
            border: none;
            background: none;
          }

          &:focus {
            border: none;
            background: none;
          }
        }
      }

      .menu-collapse-body {
        padding: 38px 30px;

        ul.menu-collapse-body-item {
          list-style: none;
          padding-inline: 0px;

          li {
            font-family: KazimirText;
            font-size: 24px;
            line-height: 28px;
            color: #FFFFFF;
            margin-bottom: 32px;

            span {
              float: right;

              button {
                background: none;
                border: none;
              }
            }
          }
        }
        .header-button-join {
          width: 100%;
          text-align: center;
          height: 35px;
          background-color: #F09B00;
          border-radius: 100px;
          margin: auto;
          margin-top: 45px;
          border-width: 0px;
          font-family: ProximaBold;
          font-size: 16px;
          line-height: 19px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          text-decoration: none;
        }
      }

      .menu-collapse-footer {
        height: 100%;

        .menu-callapse-socials {
          width: 100%;
          display: flex;
          justify-content: center;
          position: absolute;
          bottom: 60px;

          img {
            margin: 0px 13px;
          }
        }

        .menu-callapse-copyright {
          width: 100%;
          display: flex;
          justify-content: center;
          position: absolute;
          bottom: 20px;
          font-family: Proxima Nova;
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 12px;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}

.header-home {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;

  .navbar {
    z-index: 99;
    flex: 1;
    display: inline-flex;
    width: 100%;
    height: 88px;
    .header-logo {
      img {
        width: 100%;
      }
    }

    .navbar-collapse {
      justify-content: center;
  
      .navbar-nav {
        color: #fff;
        opacity: 0.5;
        font-size: 1rem;
        font-family: ProximaBold;
        margin-left: 40px;
        padding-bottom: 1rem;
        padding-top: 1rem;

        &:hover {
          cursor: pointer;
        }
  
        &.active {
          opacity: 1;
          border-bottom: 2px solid #f09b00;
        }
      }
    }

    @media only screen and (min-width: 500px) {
      .header-buttons {
        .header-button-login__black {
          display: none;
        }
      }
    }

    .header-tabs {
      text-decoration: none;
      display: -webkit-inline-box;

      @media only screen and (max-width: 1280px) {
        margin-left: 80px;
      }

      @media only screen and (max-width: 768px) {
        margin-left: 10px;
      }

      .header-tab-item,
      .header-tab-item__black {
        // margin-left: 40px;
        font-family: ProximaBold;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 100%;
        color: #FFFFFF !important;
        opacity: 0.5;
        height: 32px;
        padding-right: 0px !important;
        padding-left: 0px !important;

        &.active {
          opacity: 1;
          border-bottom: 2px solid orange;
        }

        &:hover {
          border-bottom: 2px solid #FFFFFF;
          text-decoration: none;
        }
      }
      .header-tab-item__black {
        color: #000 !important;
      }
    }
  }

  .header-buttons {
    .header-button-login{
      margin-bottom: 5px;
    }
    .header-button-login,
    .header-button-login__black {
      font-family: ProximaBold;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #FFFFFF;
      text-decoration: none;
    }

    .header-button-login__black {
      color: #333;
    }

    .header-button-join {
      width: 156px;
      text-align: center;
      height: 35px;
      background-color: #F09B00;
      border-radius: 100px;
      margin-top: -8px;
      margin-left: 26px;
      border-width: 0px;
      font-family: ProximaBold;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      padding: 8px 40px;
      text-decoration: none;
      color: #fff;
    }

    .header-nav-toggler {
      display: none;
    }
  }

  .menu-collapse {
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
    background-color: #282828;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;

    .menu-collapse-header {
      padding: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        background: none;
        border: none;

        &:hover {
          border: none;
          background: none;
        }

        &:focus {
          border: none;
          background: none;
        }
      }
    }

    .menu-collapse-body {
      padding: 38px 30px;

      ul.menu-collapse-body-item {
        list-style: none;
        padding-inline: 0px;

        li {
          font-family: KazimirText;
          font-size: 24px;
          line-height: 28px;
          color: #FFFFFF;
          margin-bottom: 32px;

          span {
            float: right;

            button {
              background: none;
              border: none;
            }
          }
        }
      }
      .header-button-join,
      .header-button-login {
        width: 100%;
        height: 35px;
        border-radius: 100px;
        margin: auto;
        border-width: 0px;
        font-family: ProximaBold;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-decoration: none;
      }
      .header-button-join {
        background-color: #F09B00;
        border-width: 0px;
      }

      .header-button-login {
        background: transparent;
        border: 1px solid #FFFFFF;
        margin-top: 20px;
      }
    }

    .menu-collapse-footer {
      height: 100%;

      .menu-callapse-socials {
        width: 100%;
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 60px;

        img {
          margin: 0px 13px;
        }
      }

      .menu-callapse-copyright {
        width: 100%;
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 20px;
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.investor-wrapper {
  .navbar-collapse {
    justify-content: flex-end;
    margin-right: 200px;

    .navbar-nav {
      color: #333 !important;
      opacity: 0.5;
      font-size: 1rem;
      font-family: ProximaBold;
      margin-left: 40px;
      border-bottom: 2px solid #fff;
      padding-bottom: 1rem;
      padding-top: 1rem;

      &.active {
        opacity: 1;
        border-bottom: 2px solid #f09b00;
      }
    }
  }
}

.header-partners {
  .navbar .navbar-collapse .navbar-nav {
    color: #333;
  }
  .navbar .navbar-collapse .navbar-nav.active {
    border-bottom: 2px solid #f09b00;
    opacity: 1;
  }
}

.header-partners {
  .navbar {
    .navbar-collapse {
      .navbar-nav {
        color: #333;
      }
    }

    .header-button-login {
      color: #333;
      opacity: 0.5;
    }
  }
}
@media only screen and (max-width: 1280px)  {
  .Layout-container {
    .header-container {
      .header {
        nav.navbar {
          padding: inherit;
        }
      }
    }
  }
}

@media only screen and (max-width: 960px)  {
  .Layout-container {
    .header-container {

      .header {
        .header-logo {
          margin-left: 16px;
        }

        .header-buttons {
          .header-button-join,
          .header-button-login {
            display: none;
          }

          .header-nav-toggler {
            display: flex;
            border: none;
            background: transparent;

            &:active {
              border: none;
              background: transparent;
            }

            &:focus {
              border: none;
              background: transparent;
            }
          }
        }
      }
    }
  }

  .header-home {  
    .header-buttons {
      .header-button-join,
      .header-button-login {
        display: none;
      }

      .header-nav-toggler {
        display: flex;
        border: none;
        background: transparent;
        padding-right: 0;
        &:active {
          border: none;
          background: transparent;
        }

        &:focus {
          border: none;
          background: transparent;
        }
      }
    }
  }

  .community .navbar .navbar-collapse .navbar-nav {
    color: #333;
  }
}

@media only screen and (max-width: 600px)  {
  .Layout-container {
    .header-img-home {
      display: none;
    }

    .header-container {
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      padding-left: 0px;
      padding-right: 0px;
      margin: 0;
      width: 100%;

      &--home {
        height: 960px !important;
        background: url(../images/M-BG.png) center center;
      }

      .header {
        padding: 0px 0px;
        z-index: 999;

        .header-logo {
          margin-left: 30px;
          padding-top: 15px;
        }

        .navbar {
          .form-inline {
            .header-buttons {
              margin-right: 15px;
              @media only screen and (max-width: 500px) {
                .header-button-login,.header-button-login_black,
                .header-button-join, .header-button-join_black {
                  display: none;
                }
          
                .header-nav-toggler_black {
                  display: block;
                  background: none;
                  border: none;
                }
              }
            }
          }
        }
      }
    }
  }
};
