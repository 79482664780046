.testimonial-slider-container {

  .slick-arrow {
    display: none !important;
  }

  .slider-button {
    button {
      background-color: transparent;
      border: none;
    }
  }

  .slider-content {
    width: 100%;
    overflow: hidden;
    display: flex;
    margin: 100px 0;
    margin-bottom: 30px;
    margin-top: 40px;

    .slick-track {
      display: flex;
    }

    .slick-slide {
      width: 550px !important;
      height: 100% !important;
      margin-right: 35px;
    }
  }

  .slider-content {
    display: flex;
    flex-direction: row;
    overflow: auto;

    .container {
      margin: 0 25px;
      width: 484px;
      // height: 332px;
      // left: 120px;
      // right: 836px;
      // top: 408px;
      // bottom: 180px;
      background: rgba(255, 255, 255, 0.08);
      border: 1px solid rgba(255, 255, 255, 0.15);
      border-radius: 24px;
      display: flex;
      justify-content: center;
    }
  }
}

@media all and (max-width: 600px) {
  .testimonial-slider-container {
    .top-section {
      .head {
        font-size: 30px;
      }

      .sub-head {
        font-size: 10px;
      }
    }

    .slider-content{ 
      .slider-item {
        width: 80% !important;
      }
    }
  }
}