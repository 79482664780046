@font-face {
  font-family: Proxima Nova;
  src: url(../../fonts/ProximaNova/ProximaNova-Regular.ttf);
}
@font-face {
  font-family: ProximaBold;
  src: url(../../fonts/ProximaNova/ProximaNova-Bold.ttf);
}
@font-face {
  font-family: ProximaBold;
  src: url(../../fonts/ProximaNova/ProximaNova-Bold.ttf);
}

@font-face {
  font-family: 'Kazimir';
  src: url(../../fonts/KazimirText/KazimirText-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kazimir';
  src: url(../../fonts/KazimirText/KazimirText-ExtraBold.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Kazimir';
  src: url(../../fonts/KazimirText/KazimirText-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(../../fonts/ProximaNova/ProximaNova-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(../../fonts/ProximaNova/ProximaNova-Medium.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(../../fonts/ProximaNova/ProximaNova-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}
