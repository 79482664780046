.startup-wrapper {
  margin-top: 100px;
  max-width: 1400px;
  margin-bottom: 300px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  .startup-decor-top {
    position: absolute;
    top: -770px;
    left: 0;
    width: 100vw;
    max-width: 1400px;
    z-index: -1;
  }

  .startup-decor-bottom {
    position: absolute;
    bottom: -600px;
    width: 100vw;
    max-width: 1400px;
    left: 0;
    z-index: -1;
  }

  .startup-decor {
    position: absolute;
    bottom: -200px;
    right: 30px;
    z-index: -1;
  }

  .content-left {
    display: flex;
    align-items: center;
    position: relative;

    .decor-paint {
      position: absolute;
      top: 255px;
      left: -10px;
      z-index: -1;
    }

    .content-block {
      width: 80%;

      .title {
        color: #333;
        font-size: 40px;
        line-height: 40px;
        font-family: 'Kazimir';
        font-weight: 900;

        p {
          font-size: 40px;
          line-height: 40px;
          font-weight: 400;
        }
      }

      .description {
        color: #535353;
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-family: 'Proxima Nova';
      }
    }
  }

  .content-right {
    position: relative;
    overflow: visible;

    .startup-img {
      width: calc(120%);
      margin-left: -10%;
    }

    .notebook-img-decor {
      position: absolute;
      z-index: -1;
      top: -96px;
      right: 73px;
    }
  }
}


@media only screen and (max-width: 600px) {
  .startup-wrapper {
    .row {
      .content-left {
        .content-block {
          width: unset;
          .title {
            font-size: 36px;
          }

          .description {
            font-size: 16px;
          }
        }
      }
    }
  }
}
