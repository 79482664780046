.footer-block-wrapper {
  .content-left {
    .signup-img {
      width: calc(130%);
      margin-left: -15%;
      margin-right: -15%;
      padding-top: 200px;
    }
  }

  .content-right {
    display: flex;
    align-items: center;
    position: relative;

    .decor-line {
      position: absolute;
      bottom: 0;
      left: -30px;
      z-index: 1;
      height: 120%;
    }

    .decor-zigzag {
      position: absolute;
      bottom: 50px;
      right: -10px;
      z-index: -1;
    }

    .content-block {
      width: 90%;
      margin-left: 10%;
      padding-top: 100px;

      .title {
        color: #333;
        font-size: 40px;
        line-height: 40px;
        font-family: 'Kazimir';
        font-weight: 900;

        span {
          font-size: 40px;
          line-height: 40px;
          font-weight: 400;
        }
      }

      .description {
        color: #535353;
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-family: 'Proxima Nova';
      }

      .signup-button {
        margin-top: 30px;
        width: 174px;
        height: 45px;
        font-size: 18px;
        line-height: 18px;
        font-family: 'Proxima Nova';
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        color: #fff !important;
        background-color: #f36328 !important;
        border: none !important;
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .footer-block-wrapper {
    .row {
      flex-direction: column-reverse;
      .content-right {
        .content-block {
          width: unset;
          margin-left: unset;

          .title {
            font-size: 36px;
            span {
              font-size: 36px;
            }
          }

          .description {
            font-size: 16px;
          }
        }
      }

      .content-left {
        margin-top: -4rem;
        padding-bottom: 4rem;
      }
    }
  }
}
