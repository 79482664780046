.market-wrapper {
  background-color: rgba(243, 99, 40, 0.04);

  .content-wrapper {
    position: relative;
  }

  .market-decor {
    position: absolute;
    top: -80px;
    left: -100px;
    z-index: -1;
  }

  .content-left {
    display: flex;
    align-items: center;
    position: relative;

    .decor-paint {
      position: absolute;
      top: 65px;
      left: -140px;
      z-index: -1;
    }

    .content-block {
      width: 90%;

      .title {
        color: #333;
        font-size: 40px;
        line-height: 40px;
        font-family: 'Kazimir';
        font-weight: 900;

        p {
          font-size: 40px;
          line-height: 40px;
          font-weight: 400;
        }
      }

      .description {
        width: 90%;
        color: #535353;
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-family: 'Proxima Nova';
      }

      .markets {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .market-button {
          margin-left: 12px;
          margin-right: 12px;
          margin-bottom: 22px;
          color: #fff !important;
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;
          background-color: #46608a !important;
          border-radius: 31px;
          height: 42px;
          padding: 12px 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none !important;
        }
      }
    }
  }

  .content-right {
    position: relative;
    overflow: visible;

    .decor-zigzag {
      position: absolute;
      top: -365px;
      left: -100px;
      z-index: -1;
    }

    .market-img {
      margin-top: -110px;
      width: calc(100% + 100px);
      margin-bottom: 40px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .market-wrapper {
    .row {
      .content-left {
        .content-block {
          width: unset;
          .title {
            font-size: 36px;
            p {
              font-size: 36px;
            }
          }
          .description {
            font-size: 16px;
            width: unset;
          }
          .markets {
            .market-button {
              font-size: 16px;
              margin-left: unset;
              height: 37px;
              padding: 6px 16px;
            }
          }
        }
      }

      .content-right {
        padding-top: 6rem;
      }
    }
  }
}
