.term-wrapper {
  .header-left {
    h1 {
      color: #333;
      font-family: 'Kazimir';
      font-size: 42px;
      line-height: 42px;
      padding-bottom: 64px;
      padding-top: 160px;
      padding-left: 60px;

      span {
        font-size: 70px;
        line-height: 70px;
        font-weight: 800;
      }
    }
  }

  .header-right {
    position: relative;

    .bg {
      position: absolute;
      bottom: -12px;
      left: 0;
    }

    .decor {
      right: 0;
      bottom: 0;
      z-index: -1;
      position: absolute;
    }
  }

  .content {
    padding: 100px;
    background-color: #F6F7F9;
    border-radius: 12px;
    position: relative;
    margin-bottom: 53px;
    padding-bottom: 200px;

    .decor-thunder {
      position: absolute;
      top: -40px;
      right: -30px;
    }

    .decor-dots {
      position: absolute;
      top: 10%;
      left: -20px;
    }

    .decor-dots-orange {
      position: absolute;
      bottom: 40%;
      right: -100px;
    }

    .decor-footer {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 271px;
      height: 211px;
      border-bottom-right-radius: 12px;
    }

    .decor-line-top {
      position: absolute;
      top: 0;
      right: -5%;
      width: 110%;
      z-index: -1;
    }

    .decor-line-bottom {
      position: absolute;
      top: 900px;
      right: -5%;
      width: 110%;
      z-index: -1;
    }

    .decor-lines {
      position: absolute;
      bottom: 50%;
      left: -50px;
    }

    .decor-triangle {
      position: absolute;
      bottom: -150px;
      left: -100px;
      z-index: -1;
    }
  }
}

@media only screen and (max-width: 960px)  {
  .term-wrapper {
    .header-right {
      display: none;
    }
  
    .header-left {
      h1 {
        color: #333;
        font-family: 'Kazimir';
        font-size: 28px;
        line-height: 28px;
        padding-bottom: 36px;
        padding-top: 80px;
        padding-left: 16px;
  
        span {
          font-size: 40px;
          line-height: 40px;
        }
      }
    }

    &.container {
      max-width: calc(100% - 16px);
    }

    .content {
      padding: 16px;

      .decor-thunder {
        position: absolute;
        top: -40px;
        overflow: hidden;
        right: -5px;
      }
  
      .decor-dots {
        display: none;
      }
  
      .decor-dots-orange {
        display: none;
      }
  
      .decor-footer {
        display: none;
      }
  
      .decor-line-top {
        display: none;
      }
  
      .decor-line-bottom {
        display: none;
      }
  
      .decor-lines {
        display: none;
      }
  
      .decor-triangle {
        position: absolute;
        bottom: -150px;
        left: -100px;
        overflow: hidden;
        z-index: -1;
      }
    }
  }
}
