.partners {
  // height: 100vh;
  width: 100%;
  .btn {
    margin-top: 24px;
    width: 174px;
    height: 45px;
    left: 134px;
    top: 504px;
    background: #F36328;
    border-radius: 100px;
    border: none;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lining {
    margin-top: 10px;
  }

  .first {
    background-image: url('../../assets/images/partner-background.png');
    background-size: cover;
    height: 732px;

    .right {
      display: flex;
      justify-content: center;
      padding: 5rem 0 0rem 0;
    }

    .left {
      position: relative;
      display: flex;
      align-items: center;

      .spiral {
        position: absolute;
        top: 10rem;
      }

      .content {
        display: flex;
        flex-direction: column;
        margin-top: 6rem;
        margin-left: 4rem;

        .join {
          font-family: 'Kazimir';
          font-style: normal;
          font-weight: 400;
          font-size: 70px;
          line-height: 100%;
          color: #333333;
        }

        .network {
          font-family: 'Kazimir';
          font-style: normal;
          font-weight: 900;
          font-size: 70px;
          line-height: 100%;
          color: #333333;
        }

        .desc {
          margin-top: 19px;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 140%;
          color: #535353;
        }

        .btn {
          margin-top: 24px;
          width: 174px;
          height: 45px;
          left: 134px;
          top: 504px;
          background: #F36328;
          border-radius: 100px;
          border: none;
        }
      }
    }
  }

  .second {
    // height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 9rem;
    padding-bottom: 9rem;

    .left {
      .img {
        background: cover;
      }
    }

    .yellow-spiral {
      position: absolute;
      height: 12rem;
      width: 10rem;
      right: 0;
      bottom: 0;
    }

    .right {
      display: flex;
      flex-direction: column;
      max-width: 489px;
      position: relative;

      .yellow {
        position: absolute;
        left: -10rem;
        top: -7rem;
        z-index: -1
      }

      .sub-head {
        font-family: 'Kazimir';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 120%;
        color: #333333;
      }

      .head {
        font-family: 'Kazimir';
        font-style: normal;
        font-weight: 900;
        font-size: 40px;
        line-height: 120%;
        color: #333333;
      }

      .desc {
        margin-top: 16px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        color: #535353;
      }

      .tick-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 34px;

        .content {
          width: 190px;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #333333;
          margin: 0px 0px 16px 0px;

          img {
            margin-right: 8px;
          }
        }
      }

      .btn {
        margin-top: 20px;
        width: 174px;
        height: 45px;
        left: 0px;
        top: 365px;
        background: #F36328;
        border-radius: 100px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
      }
    }
  }

  .third {
    display: flex;
    justify-content: center;
    align-items: center;


    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .name {
        margin-top: 32px;
        font-family: 'Kazimir';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: #333333;
      }

      .position {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 7px;
        // width: 77px;
        // height: 19px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
        margin: 10px 0px;
      }
    
      .sub-head {
        font-family: 'Kazimir';
        font-style: normal;
        font-weight: 400;
        font-size: 46px;
        line-height: 100%;
        text-align: center;
        color: #333333;
      }

      .head  {
        font-family: 'Kazimir';
        font-style: normal;
        font-weight: 900;
        font-size: 46px;
        line-height: 100%;
        text-align: center;
        color: #333333;
      }
    }

    .people {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      position: relative;
      margin-bottom: 4rem;
      padding-top: 10rem;
      padding-bottom: 10rem;

      .arlene {
        position: absolute;
        top: -2rem;
      }

      .code {
        position: absolute;
        top: 2rem;
      }

      .kathyrn {
        position: absolute;
        top: -2rem;
      }

      .guy {
        position: absolute;
        top: 2rem;
      }

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
      }
    }
  }

  .fourth {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #F9FBFE;
    position: relative;

    .wind {
      position: absolute;
      right: 0;
      top: 50px;
      width: 250px;
      height: 500px;
    }


    .content {
      display: flex;
      flex-direction: column;
      margin-left: 5rem;
      margin-top: 120px;

      .head {
        font-family: 'Kazimir';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 0.2px;
        color: #333333;
        z-index: 1;
      }

      .desc{
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #535353;
        z-index: 1;
      }
    }

    .star-containers {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 1px 1px 1px 70px;
      justify-content: space-around;
      margin-top: 48px;
      z-index: 10;

      .star-container {
        max-width: 363px;
        max-height: 162px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 24px;
        background: #FFFFFF;
        border: 1px solid #E5EAF4;
        // box-shadow: 0px 15px 35px rgb(0 0 0 / 5%);
        border-radius: 12px;
        margin: 40px 0px;

        .star {
          height: 40px;
          width: 40px;
          margin-right: 16px;
        }

        .star-content {
          display: flex;
          flex-direction: column;

          .head {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 33px;
            letter-spacing: 0.2px;
            color: #333333;
          }

          .desc {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: #535353;
          }
        }
      }
    }
  }

  .fifth {
    display: flex;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .left {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .content {
        position: relative;
        display: flex;
        flex-direction: column;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 32px;
        width: 489px;
        height: 255px;

        .head {
          font-family: 'Kazimir';
          font-style: normal;
          font-weight: 400;
          font-size: 40px;
          line-height: 120%;
          color: #333333;
        }

        .desc {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 140%;
          color: #535353;
        }

        .btn {
          width: 174px;
          height: 45px;
          background: #F36328;
          border-radius: 100px;
        }
      }

      .grey {
        position: absolute;
        left: 0;
      }
    }
  }

  .sixth {
    height: 100vh;
    width: 100vw;
    background-image: url('../../assets/images/what-we-do.png');
    position: relative;

    .heading {
      position: absolute;
      font-family: 'Kazimir';
      font-style: normal;
      font-weight: 900;
      font-size: 40px;
      line-height: 40px;
      letter-spacing: 0.2px;
      color: #333333;
      top: 20%;
      left: 39%;
    }

    .it-containers {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .container {
        display: flex;
        flex-direction: row;
        padding: 24px;
        gap: 10px;
        width: 362px;
        left: 135px;
        top: 208px;
        background: #FFFFFF;
        border: 1px solid #E5EAF4;
        box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.05);
        border-radius: 12px;

        .content {
          display: flex;
          flex-direction: column;
          margin-left: 13px;

          .head {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 33px;
            letter-spacing: 0.2px;
            color: #333333;
          }

          .desc {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: #535353;
          }
        }
      }

      .container-2 {
        position: absolute;
        top: 50%;
        left: 40%;
      }

      .container-1 {
        position: absolute;
        top: 30%;
      }

      .container-3 {
        position: absolute;
        left: 70%;
        top: 60%;
      }
    }
  }

  .seventh {
    display: flex;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .dots {
      position: absolute;
      bottom: 21rem;
      width: 17rem;
      height: 22rem;
      right: 30px;
    }

    .left {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .content {
        position: relative;
        display: flex;
        flex-direction: column;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 18px;
        width: 489px;
        margin-left: 6rem;

        .below-desc {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #333333;
          margin-left: 10px;
        }

        .sub-head {
          font-family: 'Kazimir';
          font-style: normal;
          font-weight: 400;
          font-size: 40px;
          line-height: 120%;
          color: #333333;
          margin-bottom: -30px;
        }

        .head {
          font-family: 'Kazimir';
          font-style: normal;
          font-weight: 900;
          font-size: 40px;
          line-height: 120%;
          color: #333333;
        }

        .desc {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 140%;
          color: #535353;
        }

        .btn {
          width: 174px;
          height: 45px;
          background: #F36328;
          border-radius: 100px;
        }
      }

      .yellow {
        position: absolute;
        left: 0;
      }
    }
  }

  .eight {
    padding: 120px 80px;
    position: relative;

    .hollow {
      position: absolute;
      left: 0;
      top: 0;
      height: 10rem;
      width: 10rem;
    }

    .head {
      font-family: 'Kazimir';
      font-style: normal;
      font-weight: 900;
      font-size: 40px;
      line-height: 40px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #333333;
      margin-bottom: 80px;
    }

    .arrows {
      display: flex;
      justify-content: flex-end;

      .img {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 10px;
        width: 48px;
        height: 48px;
      }
    }

    .slider-containers {
      // display: flex;
      // flex-direction: row;
      // flex-wrap: wrap;

      .slick-arrow {
        display: none !important;
      }
    
      .slider-button {
        button {
          background-color: transparent;
          border: none;
        }
      }

      .container {
        width: 545px;
        height: 290px;

        .desc {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          color: #535353;
          background: #EDEFF3;
          padding: 33px;
        }

        .below-desc {
          display: flex;
          margin-top: 24px;

          .img {
            margin-right: 22px;
          }

          .name {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.2px;
            color: #333333;
          }

          .position {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #535353;
            margin-top: 6px;
          }
        }
      }
    }
  }

  .home-final-container {
    position: relative;
    overflow: hidden;
    padding: 2rem;

    &::before{
      content: url(../../assets/images/Vector.svg);
      position: absolute;
      right: -100px;
      bottom: -70px;
    }
    .home-final-content {
      background: url(../../assets/images/home-final.png) center;
      background-size: 100%;
      background-repeat: no-repeat;
      position: relative;
      padding: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 64px;
      .home-content{
        span{
          font-size: 18px;
          line-height: 22px;
          letter-spacing: 2px;
          text-transform: uppercase;
          color: #FFE9C3;
          font-family: ProximaBold;
          opacity: 0.75;
        }
        p{
          font-family: Kazimir;
          margin-bottom: 0;
          font-style: normal;
          font-weight: bold;
          font-size: 60px;
          line-height: 70px;
          color: #FFFFFF;
        }
      }
      .home-final-content-button {
        width: 270px;
        height: 54px;
        background: #F36328;
        border-radius: 100px;
        border-width: 0px;
        font-family: ProximaBold;
        font-size: 20px;
        line-height: 24px;
        padding: 15px 86px;
        color: #fff;
        text-decoration: none;
  
        &:hover {
          background: #FFFFFF;
          color: #F36328;
        }
      }
    }
  }
}

@media all and (max-width: 600px) {
  .partners {
    .first {
      padding-inline: 10px;

      .right {
        position: relative;

        .women {
          height: 20rem;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }

      .left {
        .spiral {
          width: 10rem;
          right: 0;
          position: absolute;
        }

        .content {
          margin-left: 0;
          .join, .network {
            font-size: 36px;
          }
        }
      }
    }

    .second {
      flex-direction: column-reverse;

      .yellow-spiral {
        right: 0rem;
        top: 28rem;
      }

      .right {
        padding-inline: 30px;

        .tick-container {
          margin-top: 10px;

          .content {
            width: 210px;
            font-size: 16px;
            margin: 0;
            padding-top: 15px;
          }
        }
      }
      .left {
        .img {
          height: 22rem;
        }
      }
    }

    .third {
      .people {
        flex-direction: column;
        padding-top: 5rem;
        position: relative;

        .img {
          height: 9rem;
        }

        .arlene {
          position:absolute;
          left: 15px;
          top: 3rem;
        }
        .code {
          position: absolute;
          right: 3rem;
          top: 20rem;
        }
        .kathryn {
          position: absolute;
          top: 35rem;
          left: 15px;
        }
        .guy {
          position: absolute;
          top: 53rem;
          right: 3rem;
        }
      }

      .content {
        .sub-head {
          text-align: start;
        }
      }
    }

    .fourth {
      width: 100vw;
      .content {
        margin-left: 0;
        padding: 0px 19px;
      }

      .star-containers {
        padding: 0;
      }
    }

    .fifth {
      .left {
        padding-inline: 30px;
        .content {
          width: auto;
        }
      } 

      .right {
        .img {
          height: 18rem;
        }
      }
    }

    .sixth {
      .it-containers {
        display: flex;
        flex-direction: column;

        .container-1,.container-2,.container-3 {
          margin-bottom: 30px;
          position: unset;
          height: 110px;
          align-items: center;
        }
      }

      .heading {
        left: 0;
      }
    }

    .seventh {
      .dots {
        width: 10rem;
        height: 12rem;
        bottom: 13rem;
      }

      .left {
        .content {
          width: auto;
          padding-left: 31px;
          margin-left: 0;

          .sub-head {
            font-size: 36px;
          }

          .head {
            font-size: 36px;
            margin-top: 1rem;
          }

          .desc {
            font-size: 16px;
          }
        }
      }

      .right {
        margin-top: 3rem;
        .img {
          height: 17rem;
        }
      }
    }

    .eight {
      .content-all {
        padding-right: 20rem;
      }
    }

    .home-final-container {
      height: 700px;
      .home-final-content {
        display: flex;
        flex-direction: column;
        justify-content: start;
        // background: url(/static/media/starter-end.e93070d1.svg) no-repeat center;
        background: url(../../assets/images/mobile_footer.svg) no-repeat center;
        height: 25rem;
        margin-top: 6rem;
        border-radius: 12px;

        .home-content {
          height: 10rem;
          padding-top: 3rem;
          flex-direction: column;
          text-align: center;
          p {
            font-size: 37px;
          }
        }

        .home-final-content-button {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 3rem;
          font-size: 1rem;
          width: 150px;
          height: 34px;
          padding: unset;
        }
      }
    }
  }
}