// lib
@import "~bootstrap/scss/bootstrap";

//base
@import './base/font';

@import 'header';
@import 'footer';
@import 'footer-investor';
@import 'signup';
@import 'profile';
@import 'startup';
@import 'funding';
@import 'market';
@import 'footer-block';
@import 'term';

body {
  font-family: 'Proxima Nova';
}