.footer-container {
    width: 100%;
    background-color: #333333;
    position: relative;
    z-index: 2;

    &__light {
        background-color: #46608a;
    }

    .footer-deco {
        position: absolute;
        top: -300px;
    }

    .footer-main-content {
        display: flex;
        justify-content: space-between;

        .footer-left-container {
            margin-top: 60px;

            .footer-vertical-divide {
                display: none;
            }

            .footer-left-social {
                margin-top: 24px;
                a{
                    margin-right: 20px;
                    text-decoration: none;
                }
            }
        }

        .footer-right-container {
            padding-top: 63px;

            .footer-left-subscribe {
                .footer-left-subscribe-title {
                    font-family: 'Kazimir';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 18px;
                    color: #FFFFFF;
                }

                .footer-left-subscribe-group {
                    display: flex;
                    justify-content: center;
                    position: relative;
                    align-items: center;

                    .footer-left-subscribe-input {
                        background: #FFFFFF;
                        border: 2px solid #E2E2E2;
                        box-sizing: border-box;
                        border-radius: 100px;
                        width: 350px;
                        height: 45px;
                        font-family: Proxima Nova;
                    }

                    .footer-left-subscribe-button {
                        font-family: Proxima Nova;
                        width: 109px;
                        height: 37px;
                        background: #F09B00;
                        border-radius: 100px;
                        border-width: 0px;
                        position: absolute;
                        right: 4px;
                    }
                }
            }
        }
    }

    .footer-divide {
        height: 1px;
        width: calc(100% - 200px);
        background-color: rgba(255, 255, 255, 0.1);
        margin: 0 auto;
        margin-top: 47px !important;
        margin-bottom: 10px !important;
    }

    .footer-copyright {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .footer-copyright-content {
            text-align: center;
            font-family: Proxima Nova;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: rgba(255, 255, 255, 0.5);
        }

        .box-bottom {
            padding-bottom: 10px;
        }
        
        .all-rights-reserved {
        display: flex;
        justify-content: center;
        width: 100%;
        font-size: 0.875rem;
        color: rgba(255, 255, 255, 0.5);
        }
        
        .policy-box {
            display: flex;
            justify-content: flex-start;
        
            a {
                font-weight: 600;
                font-size: 0.875rem;
                color: rgba(255, 255, 255, 0.5);
                margin-right: 30px;
                text-decoration: none;
            }
        }
    }

    &.footer-home {
        .footer-copyright {
            height: 51px;
            display: flex;
            align-items: center;
            border-top: 1px solid #FFFFFF1A;
            .box-bottom {
                width: 100%;
            }
        }

        .footer-main-content {
            .footer-left-container {
                margin-bottom: 30px;
            }
        }
    }
}

@media only screen and(max-width: 980px) {
    .footer-container {
        height: unset;
        padding-top: 40px;
        .footer-deco {
            display: none;
        }
        .footer-divide{
            height: 0;
            margin-top: 32px !important;
        }
        .footer-copyright-content{
            margin: 0;
            padding-bottom: 10px;
        }
        .footer-main-content {
            .header-logo-image{
                width: 116px;
                height: auto;
            }
            .footer-left-container {
                width: 100%;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                margin: unset;

                .footer-vertical-divide {
                    width: 1px;
                    height: 100%;
                    background-color: #FFFFFF;
                    display: block;
                }

                .footer-left-social {
                    margin: unset;
                    a {
                        margin-right: 15px;
                    }
                }
            }

            .footer-right-container {
                display: none;
            }
        }

        &.footer-home {
            position: absolute;
            left: 0;
            bottom: 0;
            .footer-copyright {
                border-top: none;
                .box-bottom {
                    justify-content: center;
                    flex-direction: column;
                    div {
                        width: 100%;
                    }
                }
                .policy-box {
                    justify-content: center;
                    a:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}